import React from "react"
import { Link } from "gatsby"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFluidWebp from "../components/img-fluid-webp"
import ImgFluid from "../components/img-fluid"

import Reficon1 from "../images/reficon-house.inline.svg"
import Reficon2 from "../images/reficon-office.inline.svg"
import Reficon3 from "../images/reficon-museum.inline.svg"
import Reficon4 from "../images/reficon-school.inline.svg"
import Reficon5 from "../images/reficon-factory.inline.svg"
import Reficon6 from "../images/reficon-houseboat.inline.svg"
import Map from "../images/map.inline.svg"

import "../scss/site.scss"
import "../scss/index.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="freie ingenieure bau | Ingenieurbüro für Bauphysik, Brandschutz und Baugutachten"
        description="Wir unter­stützen Architekten und Bauherrn mit fach­über­greifender Expertise und ingenieur­technischer Kompetenz."
        lang="de"
      />
      <div>
        <ImgFluidWebp
          className="uk-visible@s"
          filename="img-title.jpg"
          alt="freie ingenieure bau - Titelbild"
        />
        <ImgFluidWebp
          className="uk-hidden@s"
          filename="img-title-square.jpg"
          alt="freie ingenieure bau - Titelbild"
        />
      </div>
      <div className="fib-index-container-into">
        <div className="fib-ft20 fib-ft-bold">
          freie ingenieure bau
          <br />
          Dr. Belaschk + Krätschell PartGmbB
        </div>
        <div className="fib-sp80"></div>
        <div className="fib-ft64 fib-ft-bold uk-width-3-4@s">
          Fachwissen, Erfah&shy;rung und krea&shy;tive Lösungen
        </div>
        <div className="fib-sp20 uk-hidden@s"></div>
        <div className="fib-sp60"></div>
        <div className="fib-ft28">
          Wir unterstützen unsere Auf&shy;trag&shy;geber mit
          fach&shy;über&shy;grei&shy;fen&shy;der Exper&shy;tise und
          in&shy;genieur&shy;tech&shy;nischer Kompe&shy;tenz bei der
          Pla&shy;nung und Erstel&shy;lung zeit&shy;gemäßer Ge&shy;bäude. Für
          indivi&shy;duelle Frage&shy;stel&shy;lungen erstel&shy;len wir
          Bau&shy;gut&shy;achten und erar&shy;beiten wirt&shy;schaft&shy;liche
          sowie praxis&shy;gerechte Lösun&shy;gen. Zur
          Qua&shy;li&shy;täts&shy;siche&shy;rung über&shy;prüfen wir
          Unter&shy;lagen und die Bauausführung.
        </div>
        <div className="fib-sp40"></div>
        <Link className="fib-ft24 fib-ft-bold fib-text-link" to="/team">
          <span style={{ borderBottom: `2px solid black` }}>Über uns</span>
        </Link>
        <div className="fib-sp40 uk-visible@m"></div>
        <div className="fib-sp80"></div>
      </div>

      <div className="fib-container-text">
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>
        <h3 className="fib-ft32 fib-ft-bold uk-text-center">
          Unsere Leistungen auf einen Blick
        </h3>
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>
        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-1-2@s">
            <h2 className="fib-ft24 fib-ft-medium fib-index-h2">Brandschutz</h2>
            <ul className="fib-list fib-ft24">
              <li>Brandschutzkonzepte</li>
              <li>Brandschutztechnische Bestandsanalysen</li>
              <li>Heißbemessung von Tragwerken</li>
              <li>Brand- und Rauchsimulation</li>
              <li>Flucht- und Rettungspläne</li>
            </ul>
            {
              <Link
                className="uk-button uk-button-default fib-link-button"
                to="/leistung-brandschutz"
              >
                zum Brandschutz
              </Link>
            }
          </div>
          <div className="fib-sp20 uk-hidden@m"></div>
          <div className="uk-width-1-2@s">
            <div className="fib-index-img-isobox">
              <ImgFluid
                filename="isobox-fire-protection1.png"
                alt="freie ingenieure bau - Isobox Brandschutz"
              />
            </div>
          </div>
        </div>

        <div className="fib-sp40"></div>
        <hr className="uk-visible@m" />
        <div className="fib-sp40"></div>

        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-1-2@s">
            <h2 className="fib-ft24 fib-ft-medium fib-index-h2">Bauphysik</h2>
            <ul className="fib-list fib-ft24">
              <li>Wärme- und Feuchteschutznachweise</li>
              <li>Energieeinsparnachweise (EnEV, EEWärmeG)</li>
              <li>Ausnahmen / Befreiungen von der EnEV</li>
              <li>Prüfung von EnEV-Nachweisen / Befreiungsanträgen</li>
              <li>Abdichtungsplanung und -beratung</li>
              <li>Gebäude- und Bauteilsimulation</li>
            </ul>
            <Link
              className="uk-button uk-button-default fib-link-button"
              to="/leistung-bauphysik"
            >
              zur Bauphysik
            </Link>
          </div>
          <div className="fib-sp20 uk-hidden@m"></div>
          <div className="uk-width-1-2@s">
            <div className="fib-index-img-isobox">
              <ImgFluid
                filename="isobox-building-physics1.png"
                alt="freie ingenieure bau - Isobox Bauphysik"
              />
            </div>
          </div>
        </div>

        <div className="fib-sp40"></div>
        <hr className="uk-visible@m" />
        <div className="fib-sp40"></div>

        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-1-2@s">
            <h2 className="fib-ft24 fib-ft-medium fib-index-h2">
              Baugutachten
            </h2>
            <ul className="fib-list fib-ft24">
              <li>Gerichtsgutachten</li>
              <li>Privatgutachen</li>
              <li>Schiedsgutachten</li>
              <li>Beweissicherung / Bauschadensgutachten</li>
              <li>Baubegleitende Qualitätsüberprüfung</li>
            </ul>
            <Link
              className="uk-button uk-button-default fib-link-button"
              to="/leistung-baugutachten"
            >
              zu Baugutachten
            </Link>
          </div>
          <div className="fib-sp20 uk-hidden@m"></div>
          <div className="uk-width-1-2@s">
            <div className="fib-index-img-isobox">
              <ImgFluid
                filename="isobox-building-assessment1.png"
                alt="freie ingenieure bau - Isobox Baugutachten"
              />
            </div>
          </div>
        </div>

        <div className="fib-sp40 uk-visible@m"></div>
        <hr className="uk-visible@m" />
        <div className="fib-sp80"></div>

        <div className="uk-flex uk-flex-center">
          <h3 className="fib-ft32 fib-ft-bold uk-text-center uk-width-6-12@m uk-width-8-12@s">
            Unsere Erfahrungen mit unter&shy;schied&shy;lichen Gebäudetypen
          </h3>
        </div>
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>
        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match"
          uk-grid=""
        >
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon1 alt="fib - Icon Wohngebäude" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Wohnen und Leben
              </h4>
              <ul>
                <li>Ein- und Mehr&shy;familien&shy;häuser</li>
                <li>Wohnhochhäuser</li>
                <li>Passivhäuser</li>
                <li>Studentenheime</li>
                <li>Dachaufstock&shy;ungen</li>
                <li>Dachgeschoss&shy;ausbau</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon2 alt="fib - Icon Büro und Gewerbe" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Büro und Gewerbe
              </h4>
              <ul>
                <li>Verkaufsstätten</li>
                <li>Gaststätten und Hotels</li>
                <li>Garagen und Parkhäuser</li>
                <li>Gewerbehallen</li>
                <li>Labor- und Forschungs&shy;gebäude</li>
                <li>Büro- und Verwaltungs&shy;bauten</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon3 alt="fib - Icon Kultur" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Kultur und Veranstaltungen
              </h4>
              <ul>
                <li>Museen</li>
                <li>Kirchen</li>
                <li>Versammlungs&shy;stätten</li>
                <li>Konzerthäuser</li>
                <li>Gedenkstätten</li>
                <li>Archive</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon4 alt="fib - Icon Schule" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Bildung und Gesundheit
              </h4>
              <ul>
                <li>Schul- und Hochschul&shy;bauten</li>
                <li>Bibliotheken</li>
                <li>Sportstätten</li>
                <li>Kindertagesstätten</li>
                <li>Krankenhäuser</li>
                <li>Pflegeheime</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon5 alt="fib - Icon Industrie" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Industrie und Logistik
              </h4>
              <ul>
                <li>Produktions- und Werkhallen</li>
                <li>Lagerhallen</li>
                <li>Heizkraftwerke</li>
                <li>Umspannwerk</li>
                <li>Sonderlager für radio&shy;aktive und explosive Stoffe</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body fib-card">
              <div className="fib-reficon">
                <Reficon6 alt="fib - Icon Sonstiges" />
              </div>
              <h4 className="fib-ft20 fib-ft-bold uk-card-title">
                Sondernutzung
              </h4>
              <ul>
                <li>Containerbauten</li>
                <li>Gewächshäuser</li>
                <li>Hangars</li>
                <li>Tunnel</li>
                <li>Bunker</li>
                <li>Hausboote</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="fib-sp120"></div>
        <div className="uk-flex uk-flex-center uk-text-center">
          <h1
            className="fib-ft34 fib-ftgrey-medium  uk-width-7-12@s"
            style={{ margin: `0px` }}
          >
            &bdquo;Wir unter&shy;stützen Architekt*innen und Bauherr*innen mit
            fach&shy;über&shy;greifender Expertise und ingenieur&shy;technischer
            Kompetenz.&ldquo;
          </h1>
        </div>
        <div className="fib-sp120"></div>
        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center uk-grid-large uk-grid-match"
          uk-grid=""
        >
          <div className="fib-img-partner">
            <ImgFluidWebp
              filename="img-partner1-body.jpg"
              alt="freie ingenieure bau - Hendrik Belaschk"
            />
          </div>
          <div className="fib-img-partner">
            <ImgFluidWebp
              filename="img-partner2-body.jpg"
              alt="freie ingenieure bau - Michael Krätschell"
            />
          </div>
        </div>
      </div>

      <div className="fib-sp160"></div>

      <div className="uk-card uk-card-body uk-card-primary uk-text-center">
        <div className="uk-card-title uk-width-10-12@s uk-width-8-12@m fib-cta-text">
          Wir begleiten Ihr Bau&shy;vorhaben als Planer, Berater oder Gutachter
          &ndash; immer&nbsp;persönlich.
        </div>
        <Obfuscate
          className="uk-button uk-button-primary fib-cta-button"
          email="info@freie-ingenieure-bau.de"
        >
          Anfrage senden
        </Obfuscate>
        <div className="fib-sp20"></div>
      </div>

      <div className="fib-container-text">
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>
        <h3 className="fib-ft32 fib-ft-bold uk-text-center">
          Unsere Leistungen erbringen wir deutschlandweit.
        </h3>

        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>
        <div className="uk-grid uk-grid-medium uk-flex-center" uk-grid="">
          <div className="uk-width-7-12 uk-width-5-12@s">
            <Map className="fib-map" alt="fib - Karte" />
          </div>
          <div className="fib-ft24 uk-width-7-12@s">
            <div>Die Schwerpunkte unserer Tätigkeit liegen in: </div>
            <div className="fib-sp20 uk-visible@m"></div>
            <div className="fib-sp20 fib-ft-bold">
              <ul className="fib-map-list">
                <li>Berlin / Brandenburg</li>
                <li>Hannover</li>
                <li>Hamburg</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="fib-sp40 uk-visible@m"></div>
        <div className="fib-sp80"></div>
        <div className="uk-grid uk-grid-medium uk-flex-center" uk-grid="">
          <div className="uk-width-5-12@s fib-ft24 fib-ft-bold">
            Auswahl laufender Projekte
          </div>
          <div className="uk-width-7-12@s fib-ft24">
            <ul className="fib-project-list">
              <li>Ootel.com, Berlin</li>
              <li>Industriekomplex &bdquo;Streuobstwiesen&ldquo;, Hannover</li>
              <li>Wohngebäude &bdquo;Klaagesmarkt&ldquo;, Hannover</li>
              <li>Fitnesscenter, Berlin-Charlottenburg</li>
              <li>Hotelneubau, Berlin-Spandau</li>
              <li>Polizeikommissariat Springe</li>
              <li>Wohngebäude &bdquo;Am Stein&shy;bruch&ldquo;, Hannover</li>
              <li>Neubau Pavillons an der Karl-Marx-Allee, Berlin</li>
              <li>Wohngebäude Hagelberger Straße, Berlin</li>
              <li>Seniorenzentrum &bdquo;Bethel&ldquo;, Bad Oeynhausen</li>
              <li>Shopping-Mall &bdquo;MyZeil&ldquo;, Frankfurt a.M.</li>
              <li>
                Neubau Doppel&shy;sport&shy;halle Lessing-Gymnasium, Berlin
              </li>
              <li>Umbau Bezirks&shy;amt Tempel&shy;hof-Schöneberg, Berlin</li>
              <li>Bürokomplex &bdquo;Rosenheimer Höfe&ldquo;, München</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
